export const churnFiretextActiveOptions = [
  { value: 0, text: 'Ej Churn' },
  { value: 1, text: 'Ja' },
  { value: 2, text: 'Nej' },
];

export const regretSourceOptions = [
  {
    value: 0,
    text: 'Ej ånger',
  },
  {
    value: 1,
    text: 'Samtal',
  },
  {
    value: 2,
    text: 'Brev',
  },
  {
    value: 3,
    text: 'E-post',
  },
  {
    value: 4,
    text: 'Återförsäljare',
  },
];
