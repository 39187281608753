import React, { useState } from 'react';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  LinearProgress,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { format, parseISO } from 'date-fns';

import { Checkboxes, DatePicker, Dropdown, TextInput } from '@/components/form';
import { generateStatusOptions } from '@/helpers';
import { getFormattedDate } from '@/helpers/dates';
import { usePrivateSettings } from '@/hooks';
import useUserRoles from '@/hooks/useUserRoles';
import { AlarmsHistoryModal } from '@/modules/services/components';
import { useServiceSavedLostMutation } from '@/modules/services/hooks/useServiceSavedLostMutation';
import {
  getRegDateMessage,
  getServiceInfoCardTitle,
  isServiceStatusChangeEnabled,
} from '@/modules/services/utils';
import { FiretextParamSchema } from '@/types/services';
import { FiretextStatusEnum } from '@/types/settings';
import { fieldRequired } from '@/utils/validation';

import { SavedLostSection } from '../../SavedLostSection';
import { RegretTimeMessage } from '../RegretTimeMessage';
import { churnFiretextActiveOptions, regretSourceOptions } from './constants';

const ButtonWrapper = styled('div')({
  '& button': {
    marginRight: '0.5rem',
    marginTop: '0.5rem',
  },
  '& :last-child': {
    marginRight: 0,
  },
});

type Props = {
  serviceDetails: FiretextParamSchema;
  churnDateValue: string | null;
  statusValue: FiretextStatusEnum;
  isLoading: boolean;
};

export const ServiceInfoForm = ({
  serviceDetails,
  churnDateValue,
  statusValue,
  isLoading,
}: Props) => {
  const [historyModalOpen, setHistoryModalOpen] = useState(false);

  const userRoles = useUserRoles();
  const {
    servicesStatuses,
    operatorsOptions,
    services: { firetext },
  } = usePrivateSettings();

  const statusOptions = generateStatusOptions(servicesStatuses.firetext);

  const regretStatusSelected = statusValue === 5;

  const regDateMessage = React.useMemo(
    () => getRegDateMessage(serviceDetails),
    [serviceDetails],
  );

  const canChangeStatus = isServiceStatusChangeEnabled(
    serviceDetails,
    userRoles,
  );

  const savedOrLostMutation = useServiceSavedLostMutation({
    serviceName: 'firetext',
    serviceId: serviceDetails.id,
  });

  return (
    <Card>
      <CardHeader
        title={getServiceInfoCardTitle(serviceDetails.customer)}
        sx={{ flexWrap: 'wrap', '& .MuiCardHeader-action': { marginRight: 0 } }}
        action={
          <ButtonWrapper>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={() => setHistoryModalOpen(true)}
            >
              Enhetslarm
            </Button>
          </ButtonWrapper>
        }
      />
      <Divider />
      <CardContent>
        <Box component="section">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6" display="inline">
                Orderdatum:{' '}
                {format(parseISO(serviceDetails.regDate), 'yyyy-MM-dd')}{' '}
              </Typography>
              {regDateMessage && (
                <RegretTimeMessage
                  display="inline"
                  variant="body1"
                  regretStatus={regDateMessage.status}
                >
                  ({regDateMessage.message})
                </RegretTimeMessage>
              )}
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography gutterBottom variant="h6">
                Skickad:{' '}
                {serviceDetails.sentDate
                  ? getFormattedDate(serviceDetails.sentDate, 'yyyy-MM-dd')
                  : 'Inget datum'}
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Dropdown
                label="Status"
                name="status"
                options={statusOptions}
                fieldProps={{
                  validate: fieldRequired,
                  parse: (value) => parseInt(value, 10),
                }}
                disabled={!canChangeStatus}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <DatePicker
                label="Churn-datum"
                name="churnDate"
                showClearButton
              />
            </Grid>
          </Grid>
        </Box>

        {churnDateValue && (
          <Grid container spacing={3}>
            <Grid item xs>
              <Dropdown
                label="Testad?"
                name="churnFiretextActive"
                options={churnFiretextActiveOptions}
              />
            </Grid>

            <Grid item xs>
              <Dropdown
                label="Anledning"
                name="churnFiretextReason"
                options={firetext.churnReasons}
              />
            </Grid>
          </Grid>
        )}

        {regretStatusSelected && (
          <Grid container spacing={3}>
            <Grid item xs>
              <Dropdown
                label="Källa?"
                name="regretSource"
                options={regretSourceOptions}
              />
            </Grid>

            <Grid item xs>
              <Dropdown
                label="Anledning"
                name="regretReason"
                options={firetext.regretReasons}
              />
            </Grid>
          </Grid>
        )}

        <Box component="section">
          <Typography marginY={2} variant="h6">
            Modul
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Dropdown
                label="Operatör"
                name="operatorId"
                options={operatorsOptions}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextInput label="MSISDN" name="msisdn" />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextInput label="ICC" name="icc" />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Dropdown
                label="Operatör"
                name="operatorId2"
                options={operatorsOptions}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextInput label="MSISDN" name="msisdn2" />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextInput label="ICC" name="icc2" />
            </Grid>
          </Grid>
        </Box>

        <Box component="section">
          <Typography marginY={2} variant="h6">
            Batchnummer
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextInput
                disabled
                label="Tillverkningsdatum"
                name="manufactureDate"
              />
            </Grid>
          </Grid>
        </Box>

        <Box component="section">
          <Typography marginY={2} variant="h6">
            Val
          </Typography>

          <Grid container spacing={2}>
            <Grid item>
              <Checkboxes
                options={{
                  label: 'Vänd',
                  value: !!serviceDetails?.antiregret,
                }}
                name="antiregret"
              />
            </Grid>
            <Grid item>
              <Checkboxes
                options={{
                  label: 'Anti-churn',
                  value: !!serviceDetails?.winBack,
                }}
                name="winBack"
              />
            </Grid>
            <Grid item>
              <Checkboxes
                options={{
                  label: 'Uppgraderingspolicy',
                  value: !!serviceDetails?.upgradePolicy,
                }}
                name="upgradePolicy"
              />
            </Grid>
            <Grid item>
              <Checkboxes
                options={{
                  label: 'Enhet returnerad',
                  value: !!serviceDetails?.unitReturned,
                }}
                name="unitReturned"
              />
            </Grid>
          </Grid>
        </Box>

        <SavedLostSection
          onClickSaved={() => {
            savedOrLostMutation.mutate({
              action: 'saved',
            });
          }}
          onClickLost={() => {
            savedOrLostMutation.mutate({
              action: 'lost',
            });
          }}
        />

        {isLoading && <LinearProgress />}
      </CardContent>

      {historyModalOpen && (
        <AlarmsHistoryModal
          serviceId={serviceDetails.id}
          onClose={() => {
            setHistoryModalOpen(false);
          }}
          type="firetext"
        />
      )}
    </Card>
  );
};
