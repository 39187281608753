import isEmpty from 'lodash/isEmpty';

import { Products, ServiceNamesPrivateSetting } from '@/types/settings';

export const getKickbackFields = (
  servicesNames: ServiceNamesPrivateSetting,
  isEnabledForWhiteLabel: Products,
) =>
  [
    {
      name: 'kickbackCoProtection',
      label: `Kickback ${servicesNames.coProtection}`,
      enabled: !isEmpty(isEnabledForWhiteLabel.coProtection),
    },
    {
      name: 'kickbackProtection',
      label: `Kickback ${servicesNames.protection}`,
      enabled: !isEmpty(isEnabledForWhiteLabel.protection),
    },
    {
      name: 'kickbackSinne',
      label: `Kickback ${servicesNames.sinne}`,
      enabled: !isEmpty(isEnabledForWhiteLabel.sinne),
    },
    {
      name: 'kickbackKeyTag',
      label: `Kickback ${servicesNames.keyTag}`,
      enabled: !isEmpty(isEnabledForWhiteLabel.keyTag),
    },
    {
      name: 'kickbackSticker',
      label: `Kickback ${servicesNames.sticker}`,
      enabled: !isEmpty(isEnabledForWhiteLabel.sticker),
    },
    {
      name: 'kickbackHomegate',
      label: `Kickback ${servicesNames.homegate}`,
      enabled: !isEmpty(isEnabledForWhiteLabel.homegate),
    },
  ] as const;
