import { useMemo } from 'react';
import React from 'react';

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Collapse } from '@mui/material';
import isEmpty from 'lodash/isEmpty';

import { getTasksWithPassedDeadline } from '@/api/tasks';
import { Can } from '@/components/Can';
import { queryKeys } from '@/constants/queryKeys';
import { usePrivateSettings } from '@/hooks';
import useUserRoles from '@/hooks/useUserRoles';
import { CollapsableMenuList } from '@/layouts/Main/components/Sidebar/components/SidebarNav/components/CollapsableMenuList';
import { MenuListItem } from '@/layouts/Main/components/Sidebar/components/SidebarNav/components/MenuListItem';
import { MenuListItemButton } from '@/layouts/Main/components/Sidebar/components/SidebarNav/components/MenuListItemButton';
import { USER_TYPES } from '@/modules/common/constants/entitiyNamesMap';
import { TaskTeamTypes } from '@/modules/tasks/constants/tasksModalsConstants';

import { MenuItemWithCounter } from './MenuItemWithCounter';
import { SubMenuListItem } from './SubMenuListItem';

export const AdminMenu = () => {
  const [open, setOpen] = React.useState(false);

  const userRoles = useUserRoles();

  const { servicesNames, servicesTypes, services } = usePrivateSettings();

  const adminTaskTypes = useMemo(() => {
    if (!servicesTypes) {
      return [];
    }

    return Object.keys(servicesTypes.task[TaskTeamTypes.ADMINISTRATOR]);
  }, [servicesTypes]);

  return (
    <>
      <MenuListItem>
        <MenuListItemButton onClick={() => setOpen(!open)}>
          {USER_TYPES.admin}
          {open ? <ExpandLess /> : <ExpandMore />}
        </MenuListItemButton>
      </MenuListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <CollapsableMenuList>
          <Can
            userRoles={userRoles}
            action="admin.tasks"
            yes={() => (
              <MenuItemWithCounter
                title="Ärenden"
                href="/admin/tasks"
                params={{
                  status: [0, 2, 3, 4],
                  type: adminTaskTypes,
                  team: TaskTeamTypes.ADMINISTRATOR,
                }}
                queryFn={getTasksWithPassedDeadline}
                refetchQueryKey={[queryKeys.getTasks]}
              />
            )}
          />
          <Can
            userRoles={userRoles}
            action="admin.tools.addToSvea"
            yes={() => (
              <SubMenuListItem
                title="Svea"
                href="/admin/svea?tab=Lägg till kund hos Svea"
              />
            )}
          />
          {!isEmpty(services.sinne) && (
            <Can
              userRoles={userRoles}
              action="admin.tools.addToSvea"
              yes={() => (
                <SubMenuListItem
                  title={`${servicesNames.sinne} - Verktyg`}
                  href={`/admin/${servicesNames.sinne}/tools`}
                />
              )}
            />
          )}
        </CollapsableMenuList>
      </Collapse>
    </>
  );
};
