import isEmpty from 'lodash/isEmpty';

import { getObjectKeys } from '@/helpers/tsHelpers';
import { CamelCaseServiceName } from '@/types/services';

import { usePrivateSettings } from './usePrivateSettings';

export const useGetActiveServices = () => {
  const { services } = usePrivateSettings();

  return getObjectKeys(services).filter(
    (service) => !isEmpty(services[service]),
  ) as CamelCaseServiceName[];
};
