import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';
import { Helmet } from 'react-helmet';

import { Breadcrumbs } from '@/components/Breadcrumbs';
import { PageHeader } from '@/components/PageHeader';
import { Tabs } from '@/components/Tabs/Tabs';
import { usePrivateSettings } from '@/hooks';
import { AntiChurnTable } from '@/modules/accountManagerSystem/components';

const AntiChurn = () => {
  const { services } = usePrivateSettings();

  const activeServices = omitBy(services, isEmpty);
  const tabMap = Object.entries(activeServices).map(([, value]) => ({
    title: value.name,
    view: () => <AntiChurnTable />,
  }));

  return (
    <>
      <Helmet title="Anti-churn" />
      <Breadcrumbs
        crumbs={[{ label: 'Account Manager System' }, { label: 'Anti-churn' }]}
      />
      <PageHeader margin title="Anti-churn" />
      <Tabs tabs={tabMap} />
    </>
  );
};

export default AntiChurn;
