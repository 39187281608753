import React, { useState } from 'react';

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
  Skeleton,
  Tooltip,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useForm } from 'react-final-form';

import { Checkboxes, DatePicker, Dropdown, TextInput } from '@/components/form';
import { Condition } from '@/components/form/Condition';
import { CustomerCategoryTypes } from '@/constants';
import { getServiceStatusKey } from '@/helpers';
import { getFormattedDate } from '@/helpers/dates';
import { getObjectKeys } from '@/helpers/tsHelpers';
import { usePrivateSettings } from '@/hooks';
import { useGetRmaCase } from '@/modules/customer/hooks/useGetRmaCase';
import { AlarmsHistoryModal, getRegDateMessage } from '@/modules/services';
import { CreateRmaCaseModal } from '@/modules/services/components/CreateRmaCaseModal';
import { useServiceSavedLostMutation } from '@/modules/services/hooks/useServiceSavedLostMutation';
import { SinneItem } from '@/types/services';
import { fieldRequired } from '@/utils/validation';

import { SavedLostSection } from '../../SavedLostSection';
import { RegretTimeMessage } from '../RegretTimeMessage';

type Props = {
  serviceDetails: SinneItem;
  churnDate: string;
  loadingLatestNote: boolean;
  status: string;
};

export const ServiceInfoFields = ({
  serviceDetails,
  loadingLatestNote,
  churnDate,
  status,
}: Props) => {
  const form = useForm();
  const {
    servicesStatuses,
    services: { sinne },
  } = usePrivateSettings();

  const [regretStatusKey] = React.useState(() =>
    getServiceStatusKey(servicesStatuses, 'sinne', 'Ånger'),
  );

  const [historyModalOpen, setHistoryModalOpen] = useState(false);
  const [deviceHealthCheckModalOpen, setDeviceHealthCheckModalOpen] =
    useState(false);

  const { data: rmaCase, isLoading: isLoadingRmaCase } = useGetRmaCase(
    serviceDetails.customer.id,
  );

  const rmaCaseExists = Boolean(
    rmaCase?.data?.find(
      (rmaCase) =>
        rmaCase.macAddress === serviceDetails.deviceId &&
        rmaCase.status != 'CANCELLED',
    ),
  );

  const regDateMessage = React.useMemo(
    () => getRegDateMessage(serviceDetails),
    [serviceDetails],
  );

  const statusOptions = getObjectKeys(serviceDetails.statusMap).map((key) => ({
    value: key,
    text: serviceDetails.statusMap[key],
  }));

  React.useEffect(() => {
    if (status !== regretStatusKey) {
      form.batch(() => {
        form.change('regretSource', null);
        form.change('regretReason', null);
      });
    }
  }, [status]);

  const regretSourceOptions = serviceDetails.regretSourceMap.map((key, i) => ({
    value: i,
    text: key,
  }));

  const savedOrLostMutation = useServiceSavedLostMutation({
    serviceName: 'sinne',
    serviceId: serviceDetails.id,
  });

  return (
    <>
      <Card>
        <CardHeader
          sx={{
            flexWrap: 'wrap',
            '& .MuiCardHeader-action': { marginRight: 0 },
          }}
          title={
            <span data-testid="service-details-title">
              {serviceDetails.customer.category ===
              CustomerCategoryTypes.COMPANY
                ? serviceDetails.customer.companyName
                : serviceDetails.customer.fullName}{' '}
              - {serviceDetails.customer.id}
            </span>
          }
          action={
            <ButtonWrapper>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={() => setHistoryModalOpen(true)}
              >
                Enhetslarm
              </Button>

              <Tooltip
                title={
                  rmaCaseExists
                    ? 'Det finns redan ett RMA ärende för enheten. Kontrollera status på kundkortet under ärenden.'
                    : null
                }
              >
                <span>
                  <Button
                    variant="outlined"
                    color="secondary"
                    size="small"
                    onClick={() => setDeviceHealthCheckModalOpen(true)}
                    endIcon={isLoadingRmaCase && <CircularProgress size={20} />}
                    disabled={rmaCaseExists || isLoadingRmaCase}
                  >
                    RMA
                  </Button>
                </span>
              </Tooltip>
            </ButtonWrapper>
          }
        />
        <Divider />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6">
                Orderdatum:{' '}
                {getFormattedDate(serviceDetails.regDate, 'yyyy-MM-dd')}{' '}
                {regDateMessage && (
                  <RegretTimeMessage
                    display="inline"
                    regretStatus={regDateMessage.status}
                  >
                    ({regDateMessage.message})
                  </RegretTimeMessage>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6">
                Skickad:{' '}
                {serviceDetails.sentDate
                  ? getFormattedDate(serviceDetails.sentDate, 'yyyy-MM-dd')
                  : 'Inget datum'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6">
                Onboardad:{' '}
                {serviceDetails.onboardedAt
                  ? getFormattedDate(serviceDetails.onboardedAt, 'yyyy-MM-dd')
                  : 'Inget datum'}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Dropdown options={statusOptions} label="Status" name="status" />
            </Grid>

            <Grid item xs>
              <DatePicker
                label="Churn-datum"
                name="churnDate"
                showClearButton
              />
            </Grid>

            {churnDate && (
              <Grid item xs={12} sm={6} style={{ marginInlineStart: 'auto' }}>
                <Dropdown
                  label="Anledning"
                  name="churnReason"
                  options={sinne.churnReasons}
                />
              </Grid>
            )}

            <Condition when="status" is={regretStatusKey}>
              <Grid item xs={12} sm={6}>
                <Dropdown
                  label="Anledning"
                  name="regretReason"
                  options={sinne.regretReasons}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Dropdown
                  label="Källa"
                  name="regretSource"
                  options={regretSourceOptions}
                />
              </Grid>
            </Condition>

            <Grid item xs={12}>
              <Checkboxes
                name="unitReturned"
                options={{
                  label: 'Enhet returnerad',
                  value: serviceDetails.unitReturned,
                }}
              />
              <Checkboxes
                name="antiregret"
                options={{ label: 'Vänd', value: serviceDetails.antiregret }}
              />
              <Checkboxes
                name="winback"
                options={{ label: 'Anti-churn', value: serviceDetails.winback }}
              />
            </Grid>

            <Condition when="unitReturned" is={true}>
              <Grid item xs={12}>
                {loadingLatestNote ? (
                  <Skeleton height={54} />
                ) : (
                  <TextInput
                    fieldProps={{ validate: fieldRequired }}
                    name="returnLog"
                    label="Anteckningar om returen"
                  />
                )}
              </Grid>
            </Condition>
          </Grid>

          <SavedLostSection
            onClickSaved={() => {
              savedOrLostMutation.mutate({
                action: 'saved',
              });
            }}
            onClickLost={() => {
              savedOrLostMutation.mutate({
                action: 'lost',
              });
            }}
          />
        </CardContent>
      </Card>

      {historyModalOpen && (
        <AlarmsHistoryModal
          serviceId={serviceDetails.id}
          onClose={() => {
            setHistoryModalOpen(false);
          }}
          type="sinne"
        />
      )}

      {deviceHealthCheckModalOpen && (
        <CreateRmaCaseModal
          isOpened={deviceHealthCheckModalOpen}
          handleClose={() => setDeviceHealthCheckModalOpen(false)}
          sinne={serviceDetails}
        />
      )}
    </>
  );
};

const ButtonWrapper = styled('div')({
  '& button': {
    marginRight: '0.5rem',
    marginTop: '0.5rem',
  },
  '& :last-child': {
    marginRight: 0,
  },
});
