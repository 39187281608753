import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  LinearProgress,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { getCustomSMSMessages } from '@/api/sms';
import { Checkboxes, DatePicker, Dropdown } from '@/components/form';
import { getFormattedDate } from '@/helpers/dates';
import { generateStatusOptions } from '@/helpers/productEdit';
import { usePrivateSettings } from '@/hooks';
import { useModal } from '@/hooks/useModal';
import usePublicSettings from '@/hooks/usePublicSettings';
import useUserRoles from '@/hooks/useUserRoles';
import { SavedLostSection } from '@/modules/services/components/SavedLostSection';
import { useServiceSavedLostMutation } from '@/modules/services/hooks/useServiceSavedLostMutation';
import {
  getServiceInfoCardTitle,
  prepareTypeOptions,
} from '@/modules/services/utils';
import { isServiceStatusChangeEnabled } from '@/modules/services/utils/isServiceStatusChangeable';
import { ModalTypes } from '@/state/modalManagementAtom';
import { CoProtectionParamSchema } from '@/types/services';
import { CoProtectionStatusEnum } from '@/types/settings';
import { getCustomerName } from '@/utils/customer';
import { fieldRequired } from '@/utils/validation';

import { regretSourceOptions } from './constants';

type Props = {
  serviceDetails: CoProtectionParamSchema;
  statusValue: CoProtectionStatusEnum;
  churnDateValue: null | Date;
  updateInProgress: boolean;
  protectedPhoneNr: string | null;
};

export const ServiceInfoForm = ({
  serviceDetails,
  statusValue,
  churnDateValue,
  updateInProgress,
  protectedPhoneNr,
}: Props) => {
  const userRoles = useUserRoles();
  const {
    servicesNames,
    webAddresses,
    servicesStatuses,
    servicesTypes,
    coProtectionMonitoringOptions,
    services: { coProtection },
  } = usePrivateSettings();
  const { publicSettings } = usePublicSettings();
  const { openModal } = useModal();

  const { data: customMessages } = useQuery({
    queryKey: ['custom-sms-messages'],
    queryFn: getCustomSMSMessages,
  });

  const statusOptions = generateStatusOptions(servicesStatuses.coProtection);
  const coProtectionTypes = servicesTypes.coProtection;

  const regretStatusSelected = statusValue === 2;

  const canChangeStatus = isServiceStatusChangeEnabled(
    serviceDetails,
    userRoles,
  );

  function sendActivationSMS() {
    const { customer } = serviceDetails;
    const customerName = getCustomerName(customer);

    const message = customMessages?.coProtectionActivation
      .replace('[[-customerName-]]', customerName)
      .replace('[[-coProtectionProductName-]]', servicesNames.coProtection)
      .replace('[[-protectionProductName-]]', servicesNames.protection)
      .replace('[[-webAddressMyPage-]]', webAddresses.mypage)
      .replace(
        '[[-companyNameStandard-]]',
        publicSettings?.companyNames?.standard ?? '',
      );

    openModal({
      modalType: ModalTypes.SMS_CUSTOMER,
      extra: {
        modalTitle: 'Skicka aktiverings SMS',
        messageText: `${message}`,
        disable2WayOption: true,
        isCoProtection: true,
        serviceId: serviceDetails.id,
        phoneNumbers: [protectedPhoneNr!], // TODO
        customerId: customer.id,
      },
    });
  }

  const savedOrLostMutation = useServiceSavedLostMutation({
    serviceName: 'coProtection',
    serviceId: serviceDetails.id,
  });

  return (
    <Card>
      <CardHeader
        title={getServiceInfoCardTitle(serviceDetails.customer)}
        sx={{ flexWrap: 'wrap', '& .MuiCardHeader-action': { marginRight: 0 } }}
        action={
          <Grid container item xs={12} style={{ gap: 5 }}>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              disabled={!protectedPhoneNr}
              onClick={sendActivationSMS}
            >
              Aktiverings SMS
            </Button>
          </Grid>
        }
      />
      <Divider />
      <CardContent>
        <>
          <section>
            <Typography gutterBottom variant="h6">
              Orderdatum:{' '}
              {getFormattedDate(serviceDetails.regDate, 'yyyy-MM-dd')}
            </Typography>

            <Grid container spacing={3}>
              <Grid item xs>
                <Dropdown
                  label="Status"
                  name="status"
                  options={statusOptions}
                  disabled={!canChangeStatus}
                  required
                  fieldProps={{
                    validate: fieldRequired,
                    parse: (value) => parseInt(value, 10),
                  }}
                />
              </Grid>

              <Grid item xs>
                <Dropdown
                  label="Max manuellt bevakade företag"
                  name="monitoringPlan"
                  options={coProtectionMonitoringOptions}
                  required
                  defaultValue={1}
                  fieldProps={{
                    validate: fieldRequired,
                  }}
                />
              </Grid>

              <Grid item xs>
                <Dropdown
                  label="Paket"
                  name="type"
                  options={prepareTypeOptions(coProtectionTypes)}
                  required
                  fieldProps={{
                    validate: fieldRequired,
                  }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs />

              <Grid item xs>
                <DatePicker
                  label="Churn-datum"
                  name="churnDate"
                  showClearButton
                />
              </Grid>
            </Grid>

            {regretStatusSelected && (
              <Grid container spacing={3}>
                <Grid item xs>
                  <Dropdown
                    label="Källa"
                    name="regretSource"
                    options={regretSourceOptions}
                  />
                </Grid>

                <Grid item xs>
                  <Dropdown
                    label="Anledning"
                    name="regretReason"
                    options={coProtection.regretReasons}
                  />
                </Grid>
              </Grid>
            )}

            {churnDateValue && (
              <Grid container spacing={3}>
                <Grid item xs />

                <Grid item xs>
                  <Dropdown
                    label="Anledning"
                    name="churnReason"
                    options={coProtection.churnReasons}
                  />
                </Grid>
              </Grid>
            )}
          </section>

          <section>
            <Typography variant="h6">Val</Typography>

            <Grid container spacing={2}>
              <Grid item>
                <Checkboxes
                  options={{
                    label: 'Faktureras',
                    value: Boolean(serviceDetails.billing),
                  }}
                  name="billing"
                  inputProps={{
                    // @ts-expect-error property 'data-testid' does not exist on type
                    'data-testid': 'billing',
                  }}
                />
              </Grid>
              <Grid item>
                <Checkboxes
                  options={{
                    label: 'Vänd',
                    value: Boolean(serviceDetails.antiregret),
                  }}
                  name="antiregret"
                  inputProps={{
                    // @ts-expect-error property 'data-testid' does not exist on type
                    'data-testid': 'antiregret',
                  }}
                />
              </Grid>
              <Grid item>
                <Checkboxes
                  options={{
                    label: 'Anti-churn',
                    value: Boolean(serviceDetails.winBack),
                  }}
                  name="winBack"
                  inputProps={{
                    // @ts-expect-error property 'data-testid' does not exist on type
                    'data-testid': 'winBack',
                  }}
                />
              </Grid>
            </Grid>
          </section>

          <SavedLostSection
            onClickSaved={() => {
              savedOrLostMutation.mutate({
                action: 'saved',
              });
            }}
            onClickLost={() => {
              savedOrLostMutation.mutate({
                action: 'lost',
              });
            }}
          />

          {updateInProgress && <LinearProgress />}
        </>
      </CardContent>
    </Card>
  );
};
