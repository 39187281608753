import { Grid } from '@mui/material';
import { UseMutationResult } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import isEmpty from 'lodash/isEmpty';

import { DownloadExportFileParams, ExportDownloadError } from '@/api/exports';
import { Tabs } from '@/components/Tabs/Tabs';
import { getObjectKeys } from '@/helpers/tsHelpers';
import { usePrivateSettings } from '@/hooks';
import { Products } from '@/types/settings';

import { BillableExportForm } from './BillableExportForm';
import { ExportForm } from './ExportForm';
import { OnboardingExportForm } from './OnboardingExportForm';

export type ExportTab = keyof Products | 'customer';

type Props = {
  sessionId: string;
  mutation: UseMutationResult<
    AxiosResponse<Blob, any>,
    ExportDownloadError,
    DownloadExportFileParams,
    unknown
  >;
};
export const ExportTabs = ({ sessionId, mutation }: Props) => {
  const { services: activeServices, servicesNames } = usePrivateSettings();

  const tabTitles: ExportTab[] = [...getObjectKeys(activeServices), 'customer'];

  const tabs = tabTitles
    .filter(
      (service) =>
        (service !== 'customer' &&
          !isEmpty(activeServices[service]) &&
          service) ||
        service === 'customer',
    )
    .map((tabTitle) => ({
      title: tabTitle === 'customer' ? 'Kunder' : servicesNames[tabTitle],
      view: () => (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ExportForm
              exportType={tabTitle}
              grandIdSession={sessionId}
              hasBillableOption={
                tabTitle === 'keyTag' || tabTitle === 'sticker'
              }
              mutation={mutation}
            />
          </Grid>
          {tabTitle === 'keyTag' && (
            <Grid item xs={12} sm={6}>
              <OnboardingExportForm
                exportType={tabTitle}
                grandIdSession={sessionId}
                mutation={mutation}
              />
            </Grid>
          )}
          {(tabTitle === 'keyTag' || tabTitle === 'sticker') && (
            <Grid item xs={12} sm={6}>
              <BillableExportForm
                exportType={tabTitle}
                grandIdSession={sessionId}
                mutation={mutation}
              />
            </Grid>
          )}
        </Grid>
      ),
    }));

  return <Tabs tabs={tabs} />;
};
