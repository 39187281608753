import isEmpty from 'lodash/isEmpty';

import { getObjectKeys } from '@/helpers/tsHelpers';
import { Products, ServiceNamesPrivateSetting } from '@/types/settings';

import { partnerFormSchema } from '../const/partnerFormSchema';

export const getTickFields = (
  servicesNames: ServiceNamesPrivateSetting,
  isEnabledForWhiteLabel: Products,
) =>
  [
    {
      name: 'tickCoProtection',
      label: `${servicesNames.coProtection} - Tick %`,
      enabled: !isEmpty(isEnabledForWhiteLabel.coProtection),
    },
    {
      name: 'tickProtection',
      label: `${servicesNames.protection} - Tick %`,
      enabled: !isEmpty(isEnabledForWhiteLabel.protection),
    },
    {
      name: 'tickSinne',
      label: `${servicesNames.sinne} - Tick %`,
      enabled: !isEmpty(isEnabledForWhiteLabel.sinne),
    },
    {
      name: 'tickHomegate',
      label: `${servicesNames.homegate} - Tick %`,
      enabled: !isEmpty(isEnabledForWhiteLabel.homegate),
    },
  ] as const;

export const tickFieldNames = getObjectKeys(
  partnerFormSchema
    .pick({
      tickCoProtection: true,
      tickProtection: true,
      tickSinne: true,
      tickHomegate: true,
    })
    .keyof().Values,
).map((key) => key);
