import isEmpty from 'lodash/isEmpty';

import { getObjectKeys } from '@/helpers/tsHelpers';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { CampaignTypes } from '@/types/campaigns';
import { FEATURE_FLAGS } from '@/utils/featureFlags';

import { usePrivateSettings } from './usePrivateSettings';

type Option = {
  value: CampaignTypes;
  text: string;
  id: number;
};

export const SERVICES_WITH_CAMPAIGNS = [
  'sinne',
  'homegate',
  'protection',
] as const;

export const useGetCampaignServiceOptions = () => {
  const { servicesNames, services } = usePrivateSettings();
  const protectionCampaignV2Enabled = useFeatureFlag({
    name: FEATURE_FLAGS.protectionCampaignV2.name,
  });

  const options: Array<Option> = getObjectKeys(services).reduce(
    (pVal, service, idx) => {
      if (service === 'protection' && !protectionCampaignV2Enabled) {
        return pVal;
      }
      if (
        !isEmpty(services[service]) &&
        SERVICES_WITH_CAMPAIGNS.includes(service)
      ) {
        pVal.push({
          value:
            // TODO: Move this to a helper function since it's used in at least one other place
            service === 'protection' // INFO: Needed check for V2 of protection campaigns
              ? `${service}s_campaigns`
              : (`${service}_campaigns` as CampaignTypes),
          text: servicesNames[service] || service,
          id: idx,
        });
      }

      return pVal;
    },
    [] as Option[],
  );

  return options;
};
